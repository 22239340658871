// https://polygon-mainnet.infura.io/v3/5a0e1e011860401880d5984367e68fbf
//https://polygon-rpc.com
//https://polygon-bor-rpc.publicnode.com

// testnet
/** 
const Utils = {
    contract: "0x56381652d638F319529d3A604d6b5f5d0564306d",
    rpc: "https://rpc.cardona.zkevm-rpc.com",
    chainID: 2442
}
*/

//Mainnet

const Utils = {
    contract: "0xC76BeEf9Af888208820d7E7e84C3ec4B73a7e3A9",
    rpc: "https://polygon-bor-rpc.publicnode.com",
    chainID: 137
}



export default Utils;